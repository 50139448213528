import React, { useEffect } from 'react'
import classNames from 'classnames'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import LayoutJournalPost from '../components/LayoutJournalPost'

const ns = `journal-post`

const JournalPost = ({ data }) => {
  const {
    markdownRemark: { frontmatter: content },
  } = data

  useEffect(() => {
    document.body.style.backgroundColor = '#E7E5E9'
    return function cleanup() {
      document.body.style.backgroundColor = '#ffffff'
    }
  }, [])
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  return (
    <LayoutJournalPost>
      <div className={rootClassnames}>
        <div className={`${ns}__container`}>
          <div className={`${ns}__content-container`}>
            <div className={`${ns}__sections`}>
              {content.article_post.post_visible === true ? (
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  className="post-title"
                >
                  {content.article_post.title}
                </ReactMarkdown>
              ) : (
                <h1 className={`${ns}__post-title`}>{content.label}</h1>
              )}

              {content.article_post.featured_image_section
                .featured_image_visible && (
                <img
                  src={
                    content.article_post.featured_image_section.featured_image
                      .publicURL
                  }
                  alt={content.article_post.title}
                />
              )}

              {content.article_post.post_visible && (
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {content.article_post.body}
                </ReactMarkdown>
              )}

              {content.image_post.post_visible &&
                content.image_post.images.map(image => (
                  <img src={image.image.publicURL} alt="" />
                ))}

              {content.image_post.post_visible && (
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {content.image_post.attribution}
                </ReactMarkdown>
              )}

              {content.text_post.post_visible && (
                <>
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {content.text_post.text}
                  </ReactMarkdown>

                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {content.text_post.attribution}
                  </ReactMarkdown>
                </>
              )}

              {content.video_post.post_visible && (
                <div>
                  <video muted controls>
                    <source
                      src={content.video_post.video_url}
                      type="video/mp4"
                    />
                  </video>
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {content.video_post.attribution}
                  </ReactMarkdown>
                </div>
              )}
            </div>

            {content.article_post.two_columns.column_visible && (
              <div className={`${ns}__info`}>
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                      {content.article_post.two_columns.left_column}
                    </ReactMarkdown>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <img
                      src={
                        content.article_post.two_columns.right_columns.image
                          .publicURL
                      }
                      alt={
                        content.article_post.two_columns.right_columns
                          .attribution
                      }
                    />
                    <p>
                      {
                        content.article_post.two_columns.right_columns
                          .attribution
                      }
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </LayoutJournalPost>
  )
}

export default JournalPost

export const journalPostQuery = graphql`
  query JournalPost($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        label
        date
        text_post {
          post_visible
          text
          attribution
        }
        image_post {
          post_visible
          images {
            image {
              publicURL
            }
          }
          attribution
        }
        article_post {
          post_visible
          title
          featured_image_section {
            featured_image_visible
            featured_image {
              publicURL
            }
          }
          body
          two_columns {
            column_visible
            left_column
            right_columns {
              image {
                publicURL
              }
              attribution
            }
          }
        }
        video_post {
          post_visible
          video_url
          attribution
        }
      }
    }
  }
`
