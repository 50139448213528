/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'

import HeaderTransparentDark from '../HeaderTransparentDark'
import Footer from '../Footer'
import SideLogos from '../SideLogos'

const LayoutJournalPost = ({ children }) => (
  <>
    <HeaderTransparentDark />
    <SideLogos />
    <div>
      <main>{children}</main>
    </div>
    <Footer />
  </>
)

export default LayoutJournalPost
